import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    if (window.heap) {
      const userVars = {
        email: window.currentUser.email,
        username: window.currentUser.username,
        roles: window.currentUser.roles,
        isAdmin: window.currentUser.isAdmin,
        isB2BClient: window.currentUser.isB2BClient,
      };
      window.heap.identify(window.currentUser.id);
      window.heap.addUserProperties(userVars);
    }
  }
}
